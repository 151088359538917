<template>
  <div>
    <div class="profile">
      <div class="profile-header">
        <div class="profile-header-cover"></div>
        <div class="profile-header-content">
          <div class="profile-header-img">
            <img :src="cropImg" alt="foto" @click.prevent="showFileChooser" />
          </div>
          <div class="profile-header-info m-b-15">
            <h4 class="m-t-10 m-b-5">{{ userFullName }}</h4>
            <p class="m-b-15">{{ userName }}</p>
            <a
              href="javascript:;"
              v-on:click="show('editProfil')"
              class="btn btn-xs btn-yellow"
              >Edit Profil</a
            >
            <a
              href="javascript:;"
              v-on:click="show('editPassword')"
              class="btn btn-xs btn-danger ml-2"
              >Ganti Password</a
            >
          </div>
        </div>
        <ul class="profile-header-tab nav nav-tabs">
          <li class="nav-item">
            <a
              href="javascript:;"
              v-on:click="show('about')"
              v-bind:class="{ active: tab.about }"
              class="nav-link"
              data-toggle="tab"
              >PROFIL</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="profile-content">
      <div class="tab-content p-0">
        <div class="tab-pane fade" v-bind:class="{ 'show active': tab.about }">
          <div class="table-responsive form-inline">
            <table class="table table-profile">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h4>
                      {{ userFullName }}<small>{{ userName }}</small>
                    </h4>
                    <small>{{ this.form.level }}</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">No. HP/WA</td>
                  <td>
                    <i class="fa fa-mobile fa-lg m-r-5"></i>
                    {{ this.form.phone_number }}
                  </td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">Terakhir Login</td>
                  <td>
                    {{ this.timeStampFormat(this.form.last_login) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          v-bind:class="{ 'show active': tab.editProfil }"
        >
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="table-responsive form-inline">
              <input
                v-model="form.id_users"
                type="text"
                hidden
                name="id_users"
                class="form-control m-b-5"
              />
              <input
                v-model="form.foto_lama"
                type="text"
                hidden
                name="foto_lama"
                class="form-control m-b-5"
              />
              <table class="table table-profile">
                <tbody>
                  <tr>
                    <td class="field valign-middle">Nama Lengkap</td>
                    <td>
                      <input
                        v-model="form.full_name"
                        type="text"
                        name="full_name"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.full_name,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.full_name"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Username</td>
                    <td>
                      <input
                        v-model="form.username"
                        type="text"
                        name="username"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.username,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.username"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">No. HP/WA</td>
                    <td>
                      <masked-input
                        v-model="form.phone_number"
                        type="text"
                        name="phone_number"
                        class="form-control m-b-5"
                        mask="1111-1111-1111"
                        :class="{
                          'is-invalid': formValidate.phone_number,
                        }"
                      ></masked-input>
                      <div
                        class="text-danger"
                        v-html="formValidate.phone_number"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>

                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div class="col-md-4">
                        <input
                          style="display: none"
                          ref="input"
                          type="file"
                          name="image"
                          accept="image/*"
                          @change="setImage"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="postData()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Update Profil
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div
          class="tab-pane fade"
          v-bind:class="{ 'show active': tab.editPassword }"
        >
          <form @submit.prevent="updatePassword" enctype="multipart/form-data">
            <div class="table-responsive form-inline">
              <input
                v-model="form.id_pendukung"
                type="text"
                hidden
                name="id_pendukung"
                class="form-control m-b-5"
              />
              <table class="table table-profile">
                <tbody>
                  <tr>
                    <td class="field">Password Lama</td>
                    <td>
                      <input
                        v-model="form.old_password"
                        type="password"
                        name="old_password"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.old_password,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.old_password"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field">Password Baru</td>
                    <td>
                      <input
                        v-model="form.new_password"
                        type="password"
                        name="new_password"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.new_password,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.new_password"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field">Ulangi Password Baru</td>
                    <td>
                      <input
                        v-model="form.confirm_password"
                        type="password"
                        name="confirm_password"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.confirm_password,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.confirm_password"
                      ></div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="updatePassword()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Ganti Password
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title"></h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="row">
          <div class="cropper-area">
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :aspect-ratio="1 / 1"
                :src="imgSrc"
                preview=".preview"
                style="width: 100%; border-radius: 5px; overflow: hidden"
              />
              <div v-if="this.imgSrc">
                <div class="btn-group mt-1">
                  <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    role="button"
                    @click.prevent="rotate(90)"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    role="button"
                    @click.prevent="rotate(-90)"
                  >
                    <i class="fa fa-redo fa-flip-horizontal"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    role="button"
                    @click.prevent="cropImage"
                  >
                    <i class="fa fa-crop"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button
          class="btn btn-block btn-info"
          @click="setFotoProfil()"
          variant="primary"
        >
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          Ganti Foto Profil
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import sipData from "../../plugins/sipData";
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import moment from "moment";

export default {
  mixins: [sipData],
  components: {
    MaskedInput,
    VueCropper,
  },
  data() {
    return {
      token: "",
      tab: {
        about: true,
        editProfil: false,
        editPassword: false,
      },
      form: {
        id_users: "",
        full_name: "",
        username: "",
        old_username: "",
        phone_number: "",
        level: "",
        last_login: "",
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      link: "/v1/users/update_profil",
      imgSrc: "",
      cropImg: "../assets/img/user.png",
      formValidate: [],
      loading: false,
      modalVisible: false,
      conditionMet: false,
    };
  },
  created() {
    PageOptions.pageContentFullWidth = true;
    this.fetchData();
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageContentFullWidth = false;
    next();
  },
  methods: {
    show: function (x) {
      this.tab.about = false;
      this.tab.editProfil = false;
      this.tab.editPassword = false;

      switch (x) {
        case "about":
          this.tab.about = true;
          break;
        case "editProfil":
          this.tab.editProfil = true;
          break;
        case "editPassword":
          this.tab.editPassword = true;
          break;
        default:
          this.tab.about = true;
          break;
      }
    },
    updatePassword() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post("/v1/users/password/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("sipData");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
              // this.$router.push("/profil");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                var sipData = JSON.parse(localStorage.getItem("sipData"));
                sipData.nama = this.form.full_name;
                localStorage.setItem("sipData", JSON.stringify(sipData));
                this.loading = false;
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/users/detail/" + this.userID, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.form.id_users = response.data.data.id_users;
          this.form.full_name = response.data.data.full_name;
          this.form.phone_number = response.data.data.phone_number;
          this.form.username = response.data.data.username;
          this.form.old_username = response.data.data.username;
          this.form.level = response.data.data.level;
          this.form.last_login = response.data.data.last_login;
          if (response.data.data.image_profile) {
            this.cropImg = response.data.data.image_profile;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.modalVisible = true;
      this.conditionMet = false;
      if (this.modalVisible) {
        this.$refs.input.click();
      }
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    setFotoProfil() {
      this.loading = true;
      const formData = new FormData();
      if (!this.cropImg.includes("http") || !this.cropImg == "") {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
        formData.append("id", this.form.id_users);
      }

      axios
        .post("/v1/users/image/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            var sipData = JSON.parse(localStorage.getItem("sipData"));
            sipData.image_profile = response.data.image;
            localStorage.setItem("sipData", JSON.stringify(sipData));
            this.loading = false;
            this.modalVisible = false;
            this.conditionMet = true;
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    timeStampFormat(dateTime) {
      if (dateTime) {
        return moment.unix(dateTime).format("D MMMM YYYY");
      } else {
        return "-";
      }
    },
  },
};
</script>